<template>
  <div>
    <div class="text-h5">Unsere riesige Auswahl an Bilderrahmen...</div>
    <br />
    <div class="text-body-1">Einrahmungen in perfekter Qualität!</div>
    <v-sheet style="margin-top: 1rem; margin-bottom: 1rem" max-width="100%">
      <v-img src="../../../../public/img/einrahmen/rahmenregal.jpg"></v-img>
    </v-sheet>
    <div class="text-body-1">
      In Standardgrößen bis 70 x 100 cm. Aus Holz, Metall, Glas, randlos und
      viele mehr!
    </div>
    <br />
    <div class="text-body-1">
      "Wir erstellen Maßanfertigungen in allen Größen auf Basis einer großen
      Auswahl an Musterrahmen."
    </div>
    <br />
    <div class="text-body-1">
      Schrägschnittpassepartout auf Maß geschnitten und in vielen Farben
      vorrätig. Schnell und millimetergenau geschnitten!
    </div>
    <div class="request">
      <contact-now title="Jetzt einrahmen!"></contact-now>
    </div>
  </div>
</template>

<script>
import ContactNow from "../../../components/nav/ContactNow";

export default {
  components: { ContactNow },
};
</script>

<style scoped>
.exp-panels {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.image-examples {
  display: flex;
  flex-direction: row;
}

.spacer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>